<template>
    <component
        :is="element"
        class="guideline-card"
        v-bind="attributes"
    >
        <h5 class="guideline-card__title"><slot /></h5>
    </component>
</template>

<script setup>
const { checkIsExternal } = useUri();

const props = defineProps({
    url: {
        type: String,
        required: false,
        default: null,
    },
});

const element = computed(() => {
    if (!props.url) {
        return 'article';
    }
    if (checkIsExternal(props.url)) {
        return 'a';
    }
    return resolveComponent('NuxtLink');
});

const attributes = computed(() => {
    if (!props.url) {
        return null;
    }
    if (checkIsExternal(props.url)) {
        return {
            href: props.url,
            target: '_blank',
            rel: 'noopener noreferrer',
        };
    }
    return {
        to: props.url,
    };
});
</script>

<style lang="less">
.guideline-card {
    display: block;
    padding: 1.3125rem 1.625rem;
    border: 1px solid var(--color-black-10);
    min-height: 5.75rem;

    box-shadow: var(--boxShadow-light);
    background-color: var(--color-secondary-light);
    border-radius: var(--borderRadius-sm);
    text-decoration: none;
    color: var(--color-darkest);
    transition: background-color var(--transition-fast),
        color var(--transition-fast);

    &[href] {
        &:hover,
        &:focus {
            color: var(--color-lightest);
            background-color: var(--color-secondary);
        }

        &:active {
            color: var(--color-lightest);
            background-color: var(--color-secondary-dark);
            box-shadow: var(--boxShadow-base);
        }
    }

    .vue-content-placeholders-img,
    .vue-content-placeholders-text__line {
        background: var(
            --color-black-10
        ) !important; /* stylelint-disable-line declaration-no-important */
    }

    @media @q-md-min {
        min-height: 8.875rem;
    }
}

.guideline-card__title {
    .typography-label-secondary;
}
</style>
