<template>
    <div
        v-if="!isLoading && cards.length"
        class="guideline-cards"
    >
        <BaseGuidelineCard
            v-for="(card, index) in cards"
            :key="index"
            :url="card.url"
        >
            {{ card.title }}
        </BaseGuidelineCard>
    </div>

    <div
        v-else
        class="guideline-cards"
    >
        <BaseGuidelineCard
            v-for="i in [1, 2, 3, 4, 5, 6, 7, 8]"
            :key="i"
        >
            <content-placeholders :rounded="true">
                <content-placeholders-text :lines="2" />
            </content-placeholders>
        </BaseGuidelineCard>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        cards: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>

<style lang="less">
.guideline-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.125rem;

    @media @q-md-min {
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
    }

    @media @q-lg-min {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
